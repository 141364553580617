$(document).bind('toolbar_setup', function() {
    $('.dropdown-help').hide();
    // hiding erpnext footer link
    $("div.footer-powered a.text-muted").hide();

    $('.navbar-home .erpnext-icon').hide();

    // loading site configurations from cache
    if(!('site_config' in frappe.utils)){
        frappe.call({
            method: "nerp.utils.get_site_config",
            args: {},
            callback : function(res){
                if('message' in res){
                    frappe.utils['site_config'] = res.message;
                    var site_path = frappe.utils.site_config["site_module"];
                    $('.navbar-home .erpnext-icon').show().attr('src','assets/nerp/'+site_path+'/images/erp-icon.png');
                }
            }    
        });
    }

    // redirect to designated desk in case of employee
    // if hash == empty it means it is on desk
    if(window.location.pathname=="/desk" && (window.location.hash=="" || window.location.hash.indexOf("modules/") !== -1) ){
        // check if logged in user is employee only
        if(nerp.is_only_employee()){
            window.location = frappe.utils.get_config_by_name("Desk_Page_Url_Employee", "/desk#gourmet-self-service");
        }
    }
});


$(document).ready(function(){
    $('.navbar-brand.ellipsis').attr('style','padding-top:0px !important');
    $('.footer-powered, .footer-subscribe').remove();    
    setTimeout(function(){
        $(".btn-primary.btn-login").removeClass("btn-primary").addClass("btn-default");
        $(".btn-ldap-login").removeClass("btn-default").addClass("btn-primary");
    }, 100);

    $(".btn-default.btn-ldap-login").after($(".btn-primary.btn-login"));
    
    $('.form-footer').hide();

});


 frappe.utils.check_is_active = function(name){

    if(!('site_config' in frappe.utils)){
        console.log("Site config is not loaded yet");
        return false;
    }
    let obj = frappe.utils.site_config[name];

    if(typeof obj == "undefined")
        return false;
    
    return !!+obj;

}
   
frappe.utils.get_config_by_name = function(name,default_value = ''){

    if(!('site_config' in frappe.utils)){
        return default_value;
    }
    let obj = frappe.utils.site_config[name];

    if(typeof obj == "undefined")
        return default_value;
    
    return obj;

}

frappe.utils.get_age = function(dobString, default_value = ''){

    var today = new Date();
    var birthDate = new Date(dobString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;

}

// all our custom js functions and utilities will use this instance
var NERP = function(){
    this.onlyAlphabets = function(e, t) {
        try {
            if (e) {
                var charCode = e.keyCode;
            }
            else if (e) {
                var charCode = e.which;
            }
            else { return true; }
            if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 32)
                return true;
            else
                return false;
        }
        catch (err) {
            frappe.msgprint(err.Description);
        }
    },
    this.get_login_emp_code = function(){
        var code = "";
        frappe.call({
            method: "nerp.utils.get_employee_code",
            args: {},
            async:false,
            callback : function(res){
                if('message' in res){
                    code = res.message;
                }
            }
        });
        return code;
    },
    this.is_only_employee = function(){
        var is_employee = false;
        frappe.call({
            method: "nerp.utils.is_only_employee",
            args: {},
            async:false,
            callback : function(res){
                if('message' in res){
                    is_employee = res.message;
                }
            }
        });
        return is_employee;
    }
}

NERP.prototype.methods = function(){}
NERP.prototype.utils = function(){}

window.nerp = new NERP();

$(document).on('page-change', function(e) {
    if(window.location.pathname=="/desk" && (window.location.hash=="" || window.location.hash.indexOf("modules/") !== -1) ){
        // check if logged in user is employee only
        if(nerp.is_only_employee()){
            window.location = frappe.utils.get_config_by_name("DESK_PAGE_URL_EMPLOYEE", "/desk#gourmet-self-service");
        }
    }
});


$(document).ready(function(){
    if(frappe.session.user_email != undefined){
        setTimeout(() => {
        if(frappe.utils.get_config_by_name("MULTI_TAB_NOT_ALLOW").includes(frappe.session.user_email)){
            stor.setItem("notaloowtab", true)
        }else{
            stor.setItem("notaloowtab", false)
        }
    },1000)
    }
    var stor = window.localStorage;
    window.addEventListener("load", function(e) {
        var openTabs = stor.getItem("openTabs");
        var notAllowTab = stor.getItem("notaloowtab");
        if (openTabs) {
            openTabs++;
            stor.setItem("openTabs", openTabs)
        } else {
            stor.setItem("openTabs", 1)
        }
        if(openTabs >= 2 && notAllowTab == 'true'){
            messageAlert()

        }else if(openTabs <=0 && notAllowTab == 'true'){
            messageAlert()
        }
    })
    window.addEventListener("unload", function(e) {
        e.preventDefault();
        var openTabs = stor.getItem("openTabs");
        var notAllowTab = stor.getItem("notaloowtab");
        if (openTabs && openTabs >=0) {
            openTabs--;
            stor.setItem("openTabs", openTabs)
        }else if(openTabs <=0 && notAllowTab == 'true'){
            messageAlert()
        }
        e.returnValue = '';
    });
    // window.addEventListener('storage', function(e) {
  
    // })

    function messageAlert(){
        frappe.msgprint({
            title: __('Alert'),
            indicator: 'red',
            message: __('Please close All tabs and login again. You must use one tab to use this application.')
        });
        // frappe.throw(__('Tanu ek vari smj ni andi. Bazz A ja...'))

            setTimeout(() => {
                frappe.call({
                    type:'GET',
                    method: "logout",
                    callback: function(r) {
                        window.location.reload();	
                    }
                });
            }, 4000);
    }

});

